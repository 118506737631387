.SplitedWork {
  height: 100%;
  width: 100%;
  background-color: #212020;
}
.Container {
  width: 80%;
  margin: 0 auto;
}
.SplitedWorkInside {
  background-color: #212020;
}
.SplitedWorkDescription {
  padding: 5rem 0rem;
  height: 100%;
  background-color: #212020;
}
.SplitedWorkInside img {
  width: 100%;
  margin-left: -13rem;
}
.SplitedWorkDescription small {
  color: wheat;
  font-family: Bold;
  font-size: 1.6rem;
}
.SplitedWorkDescription p {
  background-color: #212020;
  color: white;
  font-family: Medium;
  font-size: 1.6rem;
}
.SplitedWorkDescription small {
  background-color: #212020;
  display: block;
}
.SplitedWorkDescription div,
.SplitedWorkDescription a {
  background-color: #212020;
}
.SplitedWorkDescription a {
  font-family: Medium;
  color: white;
  font-size: 1.6rem;
  text-decoration: none;
}
.SplitedWorkDescription div {
  margin: 2rem 0rem;
}

@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) {
  .SplitedWork {
    height: 100vh;
    width: 100%;
  }

  .SplitedWorkDescription {
    padding: 3rem 0rem;
    height: 100%;
    background-color: #212020;
    margin-top: -10rem !important;
  }
  .SplitedWorkInside img {
    width: 100%;
    margin-left: -5rem !important;

    margin: 10rem 0rem;
  }
  .SplitedWorkDescription small {
    color: wheat;
    font-family: Bold;
    font-size: 1.6rem;
  }
  .SplitedWorkDescription p {
    background-color: #212020;
    color: white;
    font-family: Medium;
    font-size: 1.6rem;
  }
  .SplitedWorkDescription small {
    background-color: #212020;
    display: block;
  }
  .SplitedWorkDescription div,
  .SplitedWorkDescription a {
    background-color: #212020;
  }
  .SplitedWorkDescription a {
    font-family: Medium;
    color: white;
    font-size: 1.6rem;
    text-decoration: none;
  }
  .SplitedWorkDescription div {
    margin: 2rem 0rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Title h1 {
    font-size: 5rem;
    letter-spacing: 0rem;
  }
  .InsideContact {
    margin: 5rem 0rem;
    display: block;
  }

  .InsideForm {
    width: 100%;
  }
  .Message {
    width: 100%;
    margin: 3rem 0rem;
  }
}
