.About {
  height: 70vh;
}
.Container {
  width: 80%;
  margin: 0 auto;
}
.AboutInside {
  height: 70vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.AboutInside p {
  font-family: Light;
  color: white;
  font-size: 2.5rem;
  line-height: 6rem;
}

/*
  Medea Queries
*/
@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) {
  .About {
    height: 100vh;
  }
  .Container {
    width: 80%;
    margin: 0 auto;
  }
  .AboutInside {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .AboutInside p {
    font-family: Light;
    color: white;
    font-size: 2.2rem;
    line-height: 3rem;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .About {
    height: 100vh;
  }
  .AboutInside p {
    font-size: 3.5rem;
    line-height: 5rem;
  }
}
