.Work {
  width: 100%;
  height: 100%;
  padding: 5rem 0rem;
}
.Container {
  width: 80%;
  margin: 0 auto;
}
.Container a {
  text-decoration: none;
}
.Title {
  color: wheat;
  font-family: bold;
  font-size: 4rem;
  letter-spacing: 0.5rem;
}
.InsideWork {
  padding: 5rem 0rem;
  display: flex;
  flex-direction: row;
}
.InsideWork div {
  width: 50%;
}
.InsideWork h1 {
  font-family: bold;
  color: white;
}
.InsideWork p {
  color: white;
  font-family: regular;
  font-size: 1.8rem;
  width: 100%;
  margin: 2rem 0rem;
  line-height: 3rem;
}
.InsideWork img {
  width: 100%;
}
.InsideWork span {
  font-weight: light;
}

.InsideWork span {
  font-family: bold;
}
#SpecialBold {
  font-family: bold;
}

@media (min-width: 320px) and (max-width: 480px),
  (min-width: 481px) and (max-width: 767px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) and (orientation: landscape),
  (min-width: 768px) and (max-width: 1024px) {
  .Work {
    width: 100%;
    height: 100%;
    padding: 5rem 0rem;
  }

  .Title {
    color: wheat;
    font-family: bold;
    font-size: 2.5rem;
    letter-spacing: 0rem;
  }
  .InsideWork {
    text-align: center;
    padding: 5rem 0rem;
    display: block;
  }
  .InsideWork div {
    width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .Work {
    width: 100%;
    height: 100%;
    padding: 5rem 0rem;
  }

  .Title {
    color: wheat;
    font-family: bold;
    font-size: 2.5rem;
    letter-spacing: 0rem;
  }
  .InsideWork {
    text-align: center;
    padding: 5rem 0rem;
    display: block;
  }
  .InsideWork div {
    width: 100%;
  }
}
